@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Black.ttf") format("truetype");
    font-weight: 900;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Italic.ttf") format("truetype");
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Light.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
}
@font-face {
    font-family: 'Poppins';
    src: url("./Poppins-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}
