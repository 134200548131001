@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Black.ttf") format("truetype");
    font-weight: 900;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Italic.ttf") format("truetype");
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Light.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-Thin.ttf") format("truetype");
    font-weight: 100;
}
@font-face {
    font-family: 'Noto Sans';
    src: url("./NotoSans-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}
